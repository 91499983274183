var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"title":_vm.isNew
      ? _vm.t('data_processor.show.new_data_processor_agreement.title')
      : _vm.formData.title}},[_c('ModalCloseButton',{attrs:{"to":{ name: 'DataProcessorsShow' }}}),_vm._v(" "),_c('Field',{attrs:{"fill":"","type":"text","placeholder":_vm.t('data_processor.show.new_data_processor_agreement.title_placeholder')},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_vm._v(" "),_c('Field',{attrs:{"fill":"","type":"text","placeholder":_vm.t('data_processor.show.new_data_processor_agreement.link_placeholder')},model:{value:(_vm.formData.link),callback:function ($$v) {_vm.$set(_vm.formData, "link", $$v)},expression:"formData.link"}}),_vm._v(" "),_c('FieldSubHeader',[_vm._v(_vm._s(_vm.t('data_processor.show.new_data_processor_agreement.files')))]),_vm._v(" "),_c('FileUpload',{attrs:{"existing-files":_vm.existingFiles},on:{"onFilesChange":_vm.onFilesChange}}),_vm._v(" "),_c('FieldSubHeader',[_vm._v(_vm._s(_vm.t(
      'data_processor.show.new_data_processor_agreement.associated_systems_question'
    )))]),_vm._v(" "),_vm._l((_vm.systems),function(system){return _c('CheckboxField',{key:system.id,attrs:{"value":system.id,"size":"sm"},model:{value:(_vm.formData.systemIds),callback:function ($$v) {_vm.$set(_vm.formData, "systemIds", $$v)},expression:"formData.systemIds"}},[_vm._v("\n    "+_vm._s(system.name)+"\n  ")])}),_vm._v(" "),_c('ModalSaveButton',{on:{"click":function($event){return _vm.$emit('submit', _vm.formData)}}},[_vm._v("\n    "+_vm._s(_vm.isNew
        ? _vm.t('data_processor.show.new_data_processor_agreement.submit')
        : _vm.t('data_processor.show.edit_data_processor_agreement.submit'))+"\n  ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }