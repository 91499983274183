<template>
  <ContentSection>
    <Flow
      :cancel-link="cancelLink"
      :is-stepping="isNew"
      :step-names="[
        t('document.steps.start'),
        t('document.steps.review'),
        t('document.steps.other'),
        t('document.steps.files'),
        t('document.steps.documentation'),
      ]"
      @finalize="save"
    >
      <Step :step-name="t('document.steps.start')">
        <FieldContainer>
          <FieldHeader>
            <label for="title">{{ t('document.form.title_question') }}</label>
            <HelpTooltip tooltip-key="document.title" />
          </FieldHeader>
          <Field
            v-model="formData.title"
            v-validate="'required'"
            :error="errors.first('title')"
            :placeholder="t('shared.form.enter_here_placeholder')"
            id="title"
            name="title"
            type="text"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('document.form.department_question') }}
            <HelpTooltip tooltip-key="document.department" />
          </FieldHeader>
          <FormSelect v-model="formData.departmentId">
            <option
              v-for="department in departments"
              :key="department.id"
              :value="department.id"
            >
              {{ department.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldContainer :key="currentLocale()">
          <FieldHeader>
            {{ t('document.form.category_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.category">
            <option :value="null">
              {{ t('document.form.category_select_placeholder') }}
            </option>
            <option
              v-for="category in categories"
              :key="category.value"
              :value="category.value"
            >
              {{ t('document.categories.' + category.translation_key) }}
            </option>
          </FormSelect>
        </FieldContainer>
      </Step>

      <Step :step-name="t('document.steps.review')">
        <FieldContainer>
          <FieldHeader>
            {{ t('document.form.responsible_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.responsibleId">
            <option :value="null">
              {{ t('shared.form.select_responsible_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>{{ t('document.form.responsible_user_question_help_text') }}</p>
        </FieldDisclaimer>

        <FieldContainer>
          <FieldHeader>
            {{ t('document.form.executing_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.executingId">
            <option :value="null">
              {{ t('shared.form.select_executing_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>{{ t('document.form.executing_user_question_help_text') }}</p>
        </FieldDisclaimer>

        <FieldContainer>
          <FieldHeader>
            {{ t('document.form.revision_date_question') }}
            <HelpTooltip tooltip-key="document.nextRevisionDate" />
          </FieldHeader>
          <DatePicker
            v-model="formData.firstRevisionAt"
            :min-date-validator="true"
            :placeholder="t('document.form.revision_date_select_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('document.form.revision_date_interval_question') }}
            <HelpTooltip tooltip-key="reviewProcess.intervalDays" />
          </FieldHeader>
          <IntervalField v-model="formData.intervalDays" />
        </FieldContainer>
      </Step>

      <Step v-if="questions.length" :step-name="t('document.steps.other')">
        <FieldContainer v-for="question in questions" :key="question.id">
          <FieldHeader>{{ question.question }}</FieldHeader>
          <Field
            :placeholder="question.placeholder"
            :value="findOrBuildQuestionAnswer(question.id).answer"
            name="title"
            type="text"
            @input="
              value => {
                updateQuestionAnswer(question.id, value)
              }
            "
          />
        </FieldContainer>
      </Step>

      <Step :step-name="t('document.steps.files')" :step-isolated="true">
        <FieldContainer v-if="!isNew">
          <FieldHeader>{{
            t('document.form.remove_file_question')
          }}</FieldHeader>
          <List>
            <FileListItem
              v-for="(file, index) in document.files"
              :key="file.name"
              :file="file"
              :file-index="index"
              :show-close="true"
              @removed="removeFile"
            />
          </List>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('document.form.select_file_question') }}
            <HelpTooltip tooltip-key="document.files" />
          </FieldHeader>
          <FileUpload @onFilesChange="onFilesChange" />
          <HorizontalAlign v-if="!isNew" :alignment="'right'">
            <Button type="secondary" size="md" @click="save()">
              {{ t('document.form.attach') }}
            </Button>
          </HorizontalAlign>
        </FieldContainer>
      </Step>

      <Step :step-name="t('document.steps.documentation')">
        <FieldContainer>
          <FieldHeader>
            {{ t('document.form.documentation_question') }}
            <HelpTooltip tooltip-key="document.content" />
          </FieldHeader>
          <vue-editor
            v-model="formData.content"
            :editor-toolbar="customToolbar"
          />
        </FieldContainer>
        <FieldContainer>
          <FieldHeader>{{ t('document.form.link_question') }}</FieldHeader>
          <Field
            v-model="formData.externalLink"
            :placeholder="t('shared.form.enter_here_placeholder')"
            name="externalLink"
            type="text"
          />
        </FieldContainer>
      </Step>
    </Flow>
  </ContentSection>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import VueTypes from 'vue-types'
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'

import Button from '@/components/Button'
import { Flow, Step } from '@/components/Flow'
import FileUpload from '@/components/FileUpload'
import DatePicker from '@/components/DatePicker'
import ContentSection from '@/components/ContentSection'
import HelpTooltip from '@/components/HelpTooltip'
import HorizontalAlign from '@/components/HorizontalAlign'
import Field from '@/components/Field'
import FieldContainer from '@/components/FieldContainer'
import FieldHeader from '@/components/FieldHeader'
import FileListItem from '@/components/FileListItem'
import FormSelect from '@/components/FormSelect'
import List from '@/components/List'
import vueEditorToolbarConfig from '@/utils/vueEditorToolbarConfig'
import withCustomQuestions from '@/mixins/withCustomQuestions'
import FieldDisclaimer from '@/components/FieldDisclaimer'
import IntervalField from '@/components/IntervalField'

export default {
  name: 'DocumentForm',
  components: {
    Button,
    ContentSection,
    FileUpload,
    DatePicker,
    VueEditor,
    HelpTooltip,
    HorizontalAlign,
    Field,
    FieldContainer,
    FieldHeader,
    FileListItem,
    Flow,
    FormSelect,
    List,
    Step,
    FieldDisclaimer,
    IntervalField,
  },
  mixins: [withCustomQuestions],
  props: {
    document: VueTypes.shape({
      id: String,
      title: String,
      content: VueTypes.oneOfType([String, null]),
      externalLink: VueTypes.oneOfType([String, null]),
      departmentId: VueTypes.oneOfType([String, null]),
      firstRevisionAt: VueTypes.oneOfType([String, null]),
      responsibleId: VueTypes.oneOfType([String, null]),
      executingId: VueTypes.oneOfType([String, null]),
    }).loose,
  },
  data() {
    return {
      formData: cloneDeep(this.document),
      existingFiles: this.document.files,
      customToolbar: vueEditorToolbarConfig,
      cancelLinkEdit: { name: 'DocumentsRevisions' },
      cancelLinkNew: { name: 'DocumentsArchive' },
    }
  },
  computed: {
    ...mapGetters(['departments', 'questions', 'users']),

    isNew() {
      return !this.document.id
    },

    categories() {
      return [
        { value: 'political', translation_key: 'politics' },
        { value: 'procedure', translation_key: 'procedure' },
        { value: 'awareness', translation_key: 'awareness' },
        { value: 'data_processor', translation_key: 'data_processor' },
        { value: 'other', translation_key: 'other' },
      ]
    },

    cancelLink() {
      if (this.document.id) {
        return { ...this.cancelLinkEdit, params: { id: this.document.id } }
      } else {
        return this.cancelLinkNew
      }
    },
  },
  watch: {
    document: function (newDocument) {
      this.formData = cloneDeep(newDocument)
    },
  },
  mounted() {
    this.fetchCustomQuestions('documents')
    this.observeScroll()
  },
  methods: {
    ...mapActions([
      'observeScroll',
      'fetchCustomQuestions',
      'deleteDocumentFile',
    ]),

    removeFile(fileIndex) {
      this.deleteDocumentFile({ documentId: this.document.id, fileIndex })
    },

    save() {
      this.$validator
        .validateAll()
        .then(() => {
          this.$emit('submit', this.formData)
        })
        .catch(() => {
          console.log('errors exist', this.errors)
        })
    },
    onFilesChange(files) {
      this.formData.files = files
    },
  },
}
</script>
