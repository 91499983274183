var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ArchiveHeader',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),(_vm.loading)?_c('Loader'):_c('ContentSection',{staticClass:"fade_in_right"},[_c('ArchiveActionSection',[_c('ArchiveTitle',[_vm._v(_vm._s(_vm.t('data_mapping.archive.title')))]),_vm._v(" "),_c('ArchiveCreateButton',{attrs:{"to":{ name: 'DataMappingsNew' }}})],1),_vm._v(" "),_vm._l((_vm.departments),function(department){return _c('DataMappingDepartmentWrapper',{key:department.id},[(_vm.filteredDataMappingsByDepartment(department).length)?_c('div',{staticClass:"DataMappingDepartmentWrapper__box"},[_c('h2',[_vm._v(_vm._s(department.name))]),_vm._v(" "),(
            _vm.filteredDataMappingsWithDepartmentWithoutCategory(department)
              .length
          )?_c('div',{staticClass:"DataMappingDepartmentWrapper__categories"},[_c('TableWrapper',{staticClass:"active"},[_c('table',[_c('thead',{staticClass:"column-count-2"},[_c('th'),_vm._v(" "),_c('th')]),_vm._v(" "),_c('tbody',{staticClass:"column-count-2"},_vm._l((_vm.filteredDataMappingsWithDepartmentWithoutCategory(
                    department
                  )),function(dataMapping){return _c('tr',{key:dataMapping.id},[_c('td',[_c('router-link',{attrs:{"to":{ path: `data-mappings/${dataMapping.id}` }}},[_vm._v("\n                      "+_vm._s(dataMapping.title)+"\n                    ")]),_vm._v(" "),(!dataMapping.anyRiskAssessments)?_c('i',{staticClass:"fa fa-exclamation-triangle RiskWarning",on:{"click":_vm.showWarning}}):_vm._e()],1),_vm._v(" "),_c('td',[_c('ArchiveTableDropdown',{attrs:{"to-view":{ path: `data-mappings/${dataMapping.id}` },"to-edit":{
                        path: `data-mappings/${dataMapping.id}/edit`,
                      },"to-review":{
                        path: `data-mappings/${dataMapping.id}/reviews`,
                      },"resource":dataMapping},on:{"delete":_vm.handleDelete}})],1)])}),0)])])],1):_vm._e(),_vm._v(" "),_vm._l((_vm.customCategories),function(category){return _c('div',{key:category.id},[(_vm.filteredDataMappingsByCategory(category, department).length)?_c('div',{staticClass:"DataMappingDepartmentWrapper__categories"},[_c('h4',{on:{"click":function($event){return _vm.toggleTable(category.id)}}},[_c('span',{class:_vm.activeTableIds.includes(category.id)
                    ? 'fa fa-caret-down'
                    : 'fa fa-caret-right'}),_vm._v("\n              "+_vm._s(category.title)+"\n            ")]),_vm._v(" "),_c('TableWrapper',{class:_vm.activeTableIds.includes(category.id) ? 'active' : ''},[_c('table',[_c('thead',{staticClass:"column-count-2"},[_c('th'),_vm._v(" "),_c('th')]),_vm._v(" "),_c('tbody',{staticClass:"column-count-2"},_vm._l((_vm.filteredDataMappingsByCategory(
                      category,
                      department
                    )),function(dataMapping){return _c('tr',{key:dataMapping.id},[_c('td',[_c('router-link',{attrs:{"to":{ path: `data-mappings/${dataMapping.id}` }}},[_vm._v("\n                        "+_vm._s(dataMapping.title)+"\n                      ")]),_vm._v(" "),(!dataMapping.anyRiskAssessments)?_c('i',{staticClass:"fa fa-exclamation-triangle RiskWarning",on:{"click":_vm.showWarning}}):_vm._e()],1),_vm._v(" "),_c('td',[_c('ArchiveTableDropdown',{attrs:{"to-view":{ path: `data-mappings/${dataMapping.id}` },"to-edit":{
                          path: `data-mappings/${dataMapping.id}/edit`,
                        },"to-review":{
                          path: `data-mappings/${dataMapping.id}/reviews`,
                        },"resource":dataMapping},on:{"delete":_vm.handleDelete}})],1)])}),0)])])],1):_vm._e()])})],2):_vm._e()])}),_vm._v(" "),(_vm.filteredDataMappingsWithoutDepartment().length)?_c('DataMappingDepartmentWrapper',[_c('h2',[_vm._v(_vm._s(_vm.t('data_mapping.archive.general')))]),_vm._v(" "),_c('div',{staticClass:"DataMappingDepartmentWrapper__box"},[(_vm.filteredDataMappingsWithoutDepartmentWithoutCategory().length)?_c('div',{staticClass:"DataMappingDepartmentWrapper__categories"},[_c('TableWrapper',{staticClass:"active"},[_c('table',[_c('thead',{staticClass:"column-count-2"},[_c('th'),_vm._v(" "),_c('th')]),_vm._v(" "),_c('tbody',{staticClass:"column-count-2"},_vm._l((_vm.filteredDataMappingsWithoutDepartmentWithoutCategory()),function(dataMapping){return _c('tr',{key:dataMapping.id},[_c('td',[_c('router-link',{attrs:{"to":{ path: `data-mappings/${dataMapping.id}` }}},[_vm._v("\n                      "+_vm._s(dataMapping.title)+"\n                    ")]),_vm._v(" "),(!dataMapping.anyRiskAssessments)?_c('i',{staticClass:"fa fa-exclamation-triangle RiskWarning",on:{"click":_vm.showWarning}}):_vm._e()],1),_vm._v(" "),_c('td',[_c('ArchiveTableDropdown',{attrs:{"to-view":{ path: `data-mappings/${dataMapping.id}` },"to-edit":{
                        path: `data-mappings/${dataMapping.id}/edit`,
                      },"to-review":{
                        path: `data-mappings/${dataMapping.id}/reviews`,
                      },"resource":dataMapping},on:{"delete":_vm.handleDelete}})],1)])}),0)])])],1):_vm._e(),_vm._v(" "),_vm._l((_vm.customCategories),function(category){return _c('div',{key:category.id},[(
              _vm.filteredDataMappingsWithoutDepartmentWithCategory(category)
                .length
            )?_c('div',{staticClass:"DataMappingDepartmentWrapper__categories"},[_c('h4',{on:{"click":function($event){return _vm.toggleTable('general-category-' + category.id)}}},[_c('span',{class:_vm.activeTableIds.includes('general-category-' + category.id)
                    ? 'fa fa-caret-down'
                    : 'fa fa-caret-right'}),_vm._v("\n              "+_vm._s(category.title)+"\n            ")]),_vm._v(" "),_c('TableWrapper',{class:_vm.activeTableIds.includes('general-category-' + category.id)
                  ? 'active'
                  : ''},[_c('table',[_c('thead',{staticClass:"column-count-2"},[_c('th'),_vm._v(" "),_c('th')]),_vm._v(" "),_c('tbody',{staticClass:"column-count-2"},_vm._l((_vm.filteredDataMappingsWithoutDepartmentWithCategory(
                      category
                    )),function(dataMapping){return _c('tr',{key:dataMapping.id},[_c('td',[_c('router-link',{attrs:{"to":{ path: `data-mappings/${dataMapping.id}` }}},[_vm._v("\n                        "+_vm._s(dataMapping.title)+"\n                      ")]),_vm._v(" "),(!dataMapping.anyRiskAssessments)?_c('i',{staticClass:"fa fa-exclamation-triangle RiskWarning",on:{"click":_vm.showWarning}}):_vm._e()],1),_vm._v(" "),_c('td',[_c('ArchiveTableDropdown',{attrs:{"to-view":{ path: `data-mappings/${dataMapping.id}` },"to-edit":{
                          path: `data-mappings/${dataMapping.id}/edit`,
                        },"to-review":{
                          path: `data-mappings/${dataMapping.id}/reviews`,
                        },"resource":dataMapping},on:{"delete":_vm.handleDelete}})],1)])}),0)])])],1):_vm._e()])})],2)]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }