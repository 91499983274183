<template>
  <div class="StepNavigator">
    <div class="StepNavigator__list">
      <button
        v-for="(stepName, index) in stepNames"
        :key="index"
        :class="findClass(stepName)"
        @click="goToStep(stepName)"
      >
        {{ stepName }}
      </button>
    </div>
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'StepNavigator',
  inject: [
    'goToStep',
    'getActiveStep',
    'getStepRequiredFieldsByName',
    'isOptionalStep',
  ],
  props: {
    stepNames: { type: Array },
    stepErrors: { type: Object, optional: true },
  },
  data: () => ({
    activeStep: 0,
  }),
  methods: {
    findClass(stepName) {
      let classStr = 'StepNavigator__list--item'

      if (this.getActiveStep() === stepName) {
        classStr = classStr + ' active'
      }
      if (this.isOptionalStep(stepName)) {
        classStr = classStr + ' optional'
      }

      if (this.errorsInStep(stepName)) {
        classStr += ' alert'
      }

      return classStr
    },

    errorsInStep(stepName) {
      const stepRequiredFields = this.getStepRequiredFieldsByName(stepName)
      if (stepRequiredFields.length === 0) return false
      const errors = this.stepErrors.items.filter(item =>
        stepRequiredFields.includes(item.field)
      )
      return errors.length !== 0
    },
  },
}
</script>
